import type { LocationQueryRaw, RouteLocationNormalized } from "vue-router";
import type { ActivityDegree, Diet, Sex, UnitMass } from "~/types/onboarding/registrationParams";
import type { GoalTypeAbbr } from "~/types/onboarding/onboardingGoal";

interface DummyParams extends LocationQueryRaw {
  user_uuid: string;
  lang: string;
  goal: GoalTypeAbbr;
  currentWeight: string;
  goalWeight: string;
  weightUnit: UnitMass;
  gender: Sex;
  height?: string;
  ft?: string;
  in?: string;
  birthday?: string;
  bday?: string;
  bmonth?: string;
  byear?: string;
  utm_medium: string;
  utm_source: string;
  utm_campaign: string;
  utm_content: string;
  utm_term: string;
  flowid?: string;
  trial: string;
  diet?: Diet;
  activity_degree?: ActivityDegree;
  testname1?: string;
  testvariant1?: string;
  testname2?: string;
  testvariant2?: string;
}

interface LocalDummyMap {
  [key: string]: () => DummyParams;
}

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized) => {
  const config = useRuntimeConfig();
  const { $pinia } = useNuxtApp();

  const onboardingStore = useOnboardingStore($pinia);
  const discountStore = useDiscountStore($pinia);
  const userStore = useUserStore($pinia);

  if (config.public.appEnv !== "dev") {
    return;
  }

  if (to.query.dummy) {
    userStore.resetUserAbTests();

    const query = getQueryParams(to.query.dummy as string);

    if (discountStore.code !== "") {
      query.code = discountStore.code;
    }

    if (onboardingStore.registrationParams.incomplete) {
      query.incomplete = onboardingStore.registrationParams.incomplete ? "1" : "0";
    }

    return navigateTo({
      ...to,
      query,
      replace: true,
    });
  }

  function getQueryParams(dummyType: string): DummyParams {
    const dummyMap: LocalDummyMap = {
      de: getDeDummy,
      det: () => getDeDummy(true),
      us: getUsDummy,
      ust: () => getUsDummy(true),
      nl: getNlDummy,
      nlt: () => getNlDummy(true),
    };
    return dummyMap[dummyType]();
  }

  function getDeDummy(isTrial: boolean = false): DummyParams {
    return {
      user_uuid: uuidv4(),
      lang: "de",
      goal: isTrial ? "lose" : "gain",
      currentWeight: "90",
      goalWeight: isTrial ? "80" : "100",
      weightUnit: "kg",
      gender: "male",
      height: "180",
      birthday: "02.08.1986",
      utm_medium: "@utm_medium",
      utm_source: "@utm_source",
      utm_campaign: "@utm_campaign",
      utm_content: "@utm_content",
      utm_term: "@utm_term",
      flowid: "m37-de",
      trial: isTrial ? "1" : "0",
      activity_degree: "very_high",
    };
  }

  function getUsDummy(isTrial: boolean = false): DummyParams {
    return {
      user_uuid: uuidv4(),
      lang: "en",
      goal: isTrial ? "maintain" : "build_muscle",
      currentWeight: "180",
      goalWeight: isTrial ? "180" : "200",
      weightUnit: "lb",
      gender: "male",
      ft: "6",
      in: "0",
      bday: "1",
      bmonth: "01",
      byear: "1993",
      utm_medium: "@utm_medium",
      utm_source: "@utm_source",
      utm_campaign: "@utm_campaign",
      utm_content: "@utm_content",
      utm_term: "@utm_term",
      flowid: "2234",
      trial: isTrial ? "1" : "0",
      testname1: "test-feature",
      testvariant1: "test-feature-variant",
      testname2: "test-feature-other",
      testvariant2: "test-feature-other-variant",
    };
  }

  function getNlDummy(isTrial: boolean = false): DummyParams {
    return {
      user_uuid: uuidv4(),
      lang: "nl",
      goal: "gain",
      currentWeight: "85",
      goalWeight: "90",
      weightUnit: "kg",
      gender: "male",
      height: "180",
      bday: "14",
      bmonth: "11",
      byear: "1980",
      utm_medium: "@utm_medium",
      utm_source: "@utm_source",
      utm_campaign: "@utm_campaign",
      utm_content: "@utm_content",
      utm_term: "@utm_term",
      flowid: "4234",
      trial: isTrial ? "1" : "0",
      activityDegree: "low",
    };
  }
});
